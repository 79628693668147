import * as building from "../../lottie/howtoplay-building.json";
import Lottie from "lottie-react";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export default function Desktop({ handleOpen }) {
  const [ref1, inView1] = useInView({
    triggerOnce: false,
  });

  const [play1, setPlay1] = useState(false);

  useEffect(() => {
    setPlay1(inView1);
  }, [inView1]);

  return (
    <>
      <BuildingAnimation ref={ref1} inview={play1}>
        {play1 && <Lottie animationData={building} loop={true} />}
      </BuildingAnimation>
      <Frame>
        <BackgroundEmbed
          src="/asset/image/howtoplay/howtoplay.svg"
          type="image/svg+xml"
          aria-label=""
        ></BackgroundEmbed>
        <VideoButton onClick={handleOpen} />
      </Frame>
    </>
  );
}

const Frame = styled.div`
  width: 2600px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

const VideoButton = styled.button`
  position: absolute;
  width: 220px;
  height: 50px;
  top: 435px;
  left: 835px;
  background-color: transparent;
`;

const BackgroundEmbed = styled.embed`
  position: relative;
  width: 100%;
`;

const FrontEmbed = styled.embed`
  position: absolute;
  z-index: 1;
  width: 967.47px;
  left: 50%;
  transform: translateX(-50%);
`;

const BuildingAnimation = styled.div`
  position: absolute;
  width: 2800px;
  top: 35px;
  overflow-x: hidden;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.25s ease-in;
  opacity: ${(props) => (props.inview ? 1 : 0)};
`;
