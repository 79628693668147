import { colorPrimarySelector, headerIndexState } from "../../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "@emotion/styled";
import * as greenEarth from "../../lottie/whitepaer-green-earth.json";
import * as hero from "../../lottie/whitepaper-hero.json";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export default function Desktop() {
  const [ref1, inView1] = useInView({
    triggerOnce: false,
  });

  const [play1, setPlay1] = useState(false);

  useEffect(() => {
    setPlay1(inView1);
  }, [inView1]);

  return (
    <Frame>
      <HeroAnimation>
        <Lottie animationData={hero} />
      </HeroAnimation>
      <embed
        src="/asset/image/whitepaper/m-whitepaper1.svg"
        type="image/svg+xml"
        aria-label=""
      ></embed>
      <LayerWrap>
        <embed
          src="/asset/image/whitepaper/m-whitepaper2.svg"
          type="image/svg+xml"
          aria-label=""
        ></embed>
        <GreenEarthAnimation inview={play1} ref={ref1}>
          {play1 && <Lottie animationData={greenEarth} loop={false} />}
        </GreenEarthAnimation>
      </LayerWrap>
      <ColorBar className="asdasd" />
    </Frame>
  );
}

const Frame = styled.div`
  max-width: 100%;
  width: 100%;
  position: relative;
`;

const ColorBar = styled.div`
  position: absolute;
  width: 100vw;
  height: 5vw;
  background-color: #224938;
  bottom: 0;
`;

const GreenEarthAnimation = styled.div`
  position: absolute;
  width: 90vw;
  bottom: 40vw;
  right: 1.5vw;
  opacity: ${(props) => (props.inview ? 1 : 0)};
  transition: all 0.25s ease-in;
`;

const HeroAnimation = styled.div`
  position: absolute;
  width: 40vw;
  top: 57vw;
  left: 15.5vw;
  z-index: 10;
`;

const LayerWrap = styled.div`
  position: absolute;
  z-index: 20;
  top: 78vw;
  width: 100%;
`;
