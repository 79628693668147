import { useEffect } from "react";
import Layout from "../components/Layout";
import { colorPrimarySelector, headerIndexState } from "../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isMobileState } from "../recoil/atoms";
import Mobile from "../containers/whitepaper/Mobile";
import Desktop from "../containers/whitepaper/Desktop";
export default function Whitepaper() {
  const setHeaderIndex = useSetRecoilState(headerIndexState);
  useEffect(() => {
    setHeaderIndex(3);
  }, []);
  const isMobile = useRecoilValue(isMobileState);

  return (
    <Layout>
      {isMobile && <Mobile />}
      {!isMobile && <Desktop />}
    </Layout>
  );
}
