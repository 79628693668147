import styled from "@emotion/styled";
import First from "./MobileFirst";
import * as cloud from "../../lottie/about-cloud.json";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export default function Mobile() {
  const [ref1, inView1] = useInView({
    triggerOnce: false,
  });

  const [play1, setPlay1] = useState(false);

  useEffect(() => {
    setPlay1(inView1);
  }, [inView1]);

  return (
    <>
      <Frame>
        <BackgroundEmbed
          src="/asset/image/about/m-about-background.svg"
          type="image/svg+xml"
          aria-label=""
        ></BackgroundEmbed>
        <FrontEmbed
          src="/asset/image/about/m-about-foreground.svg"
          type="image/svg+xml"
          aria-label=""
        ></FrontEmbed>
      </Frame>
      <First />

      <CloudAnimation inview={play1} ref={ref1}>
        {play1 && <Lottie animationData={cloud} loop={false} />}
      </CloudAnimation>
      <CloudAnimationPlayPosition ref={ref1} />
    </>
  );
}

const Frame = styled.div`
  max-width: 100%;
  width: 100%;
  position: relative;
`;
const BackgroundEmbed = styled.embed`
  position: relative;
  width: 100%;
`;

const FrontEmbed = styled.embed`
  position: absolute;
  z-index: 3;
  width: 100%;
  top: 28vw;
  left: 50%;
  transform: translateX(-50%);
`;

const CloudAnimation = styled.div`
  position: absolute;
  width: 215vw;
  overflow-x: hidden;
  top: 190vw;
  left: -54vw;
  transition: all 0.25s ease-in;
  opacity: ${(props) => (props.inview ? 1 : 0)};
`;

const CloudAnimationPlayPosition = styled.div`
  position: absolute;
  width: 100vw;
  height: 65vw;
  overflow-x: hidden;
  top: 230vw;
`;

const ColorBar = styled.div`
  position: absolute;
  width: 100%;
  height: 2vw;
  background-color: #253964;
  bottom: 0;
`;
