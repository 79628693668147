import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { headerIndexState, colorThirdSelector } from "../../recoil/atoms";
import { Link } from "react-router-dom";

export default function DesktopHeader() {
  const headerIndex = useRecoilValue(headerIndexState);

  const colorThird = useRecoilValue(colorThirdSelector);
  return (
    <Frame>
      <Wrap>
        <LogoWrap>
          <Link to="/">
            <img src="/asset/image/logo.svg" alt="logo" />
          </Link>
        </LogoWrap>
        <MenuWrap color={colorThird}>
          <li className={headerIndex === 1 ? "active" : ""}>
            <Link to="/about">About</Link>
          </li>
          <li className={headerIndex === 2 ? "active" : ""}>
            <Link to="/howtoplay">How to play</Link>
          </li>
          <li>
            <Link to="https://linktr.ee/forthedog" target="_blank">
              Linktree
            </Link>
          </li>
          <li className={headerIndex === 3 ? "active" : ""}>
            <Link to="/whitepaper">Whitepaper</Link>
          </li>
          <li className={headerIndex === 4 ? "active" : ""}>
            <Link to="/faq">FAQ</Link>
          </li>
          <li>
            <button
              onClick={() => window.open("https://market.forthedog.life")}
            >
              MARKETPLACE
            </button>
          </li>
        </MenuWrap>
      </Wrap>
    </Frame>
  );
}

const Frame = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: "transparent";
  z-index: 999;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 966px;
  width: 100%;
  padding-top: 30px;
  margin: 0 auto;
`;

const LogoWrap = styled.div`
  & img {
    width: 248px;
  }
`;

const MenuWrap = styled.ul`
  display: flex;
  align-items: center;
  color: white;

  & > li {
    font-size: 16px;
    font-weight: 700;
    padding: 4px 13px;
    border-right: 1px solid #fff;
    text-transform: uppercase;
    &:last-child {
      border-right: none;
    }
  }

  & button {
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
  }

  & li.active a {
    color: ${(props) => props.color};
  }
`;
