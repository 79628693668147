import { useEffect } from "react";
import { isMobileState } from "../recoil/atoms";
import { useSetRecoilState } from "recoil";

const useResize = () => {
  const setIsMobileState = useSetRecoilState(isMobileState);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileState(window.innerWidth < 768);
    };

    handleResize(); // 컴포넌트가 마운트될 때 초기 너비 설정

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
};

export default useResize;
