import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import {
  headerIndexState,
  colorThirdSelector,
  colorFooterSelector,
} from "../../recoil/atoms";
import { Link } from "react-router-dom";

export default function DesktopFooter() {
  const headerIndex = useRecoilValue(headerIndexState);
  const colorThird = useRecoilValue(colorThirdSelector);
  const colorFooter = useRecoilValue(colorFooterSelector);
  return (
    <Frame themeColor={colorThird}>
      <Wrap>
        <LogoWrap>
          <Link to="/">
            <img
              src={
                (headerIndex === 0 && "/asset/image/logo-home.svg") ||
                "/asset/image/logo.svg"
              }
              alt="logo"
            />
          </Link>
        </LogoWrap>

        <div>
          <MenuWrap
            color={colorFooter}
            className={(headerIndex === 0 && "home") || ""}
          >
            <li className={headerIndex === 1 ? "active" : ""}>
              <Link to="/about">About</Link>
            </li>
            <li className={headerIndex === 2 ? "active" : ""}>
              <Link to="/howtoplay">How to play</Link>
            </li>
            <li>
              <Link to="https://linktr.ee/forthedog">Linktree</Link>
            </li>
            <li className={headerIndex === 3 ? "active" : ""}>
              <Link to="/whitepaper">Whitepaper</Link>
            </li>
            <li className={headerIndex === 4 ? "active" : ""}>
              <Link to="/faq">FAQ</Link>
            </li>
            <li>
              <button
                onClick={() => window.open("https://market.forthedog.life")}
              >
                MARKETPLACE
              </button>
            </li>
          </MenuWrap>
          <SnsWrap>
            <Link to="https://twitter.com/FortheDog_">
              <img
                src={
                  (headerIndex === 0 && "/asset/image/sns/home/twitter.svg") ||
                  "/asset/image/sns/twitter.svg"
                }
                alt="twitter"
              />
            </Link>
            <Link to="https://t.me/FORTHEDOG">
              <img
                src={
                  (headerIndex === 0 && "/asset/image/sns/home/telegram.svg") ||
                  "/asset/image/sns/telegram.svg"
                }
                alt="telegram"
              />
            </Link>

            <Link to="https://www.instagram.com/forthedog_life">
              <img
                src={
                  (headerIndex === 0 &&
                    "/asset/image/sns/home/instagram.svg") ||
                  "/asset/image/sns/instagram.svg"
                }
                alt="instagram"
              />
            </Link>

            <Link to="https://discord.gg/UgDk3JAVn2">
              <img
                src={
                  (headerIndex === 0 && "/asset/image/sns/home/discord.svg") ||
                  "/asset/image/sns/discord.svg"
                }
                alt="discord"
              />
            </Link>

            <Link to="https://medium.com/@social_29195">
              <img
                src={
                  (headerIndex === 0 && "/asset/image/sns/home/midium.svg") ||
                  "/asset/image/sns/midium.svg"
                }
                alt="midium"
              />
            </Link>

            <Link to="https://www.youtube.com/channel/UCB5QGd7wJVVRbMeghD0rqBg ">
              <img
                src={
                  (headerIndex === 0 && "/asset/image/sns/home/youtube.svg") ||
                  "/asset/image/sns/youtube.svg"
                }
                alt="youtube"
              />
            </Link>

            <Link to="https://www.reddit.com/user/forthedog_official">
              <img
                src={
                  (headerIndex === 0 && "/asset/image/sns/home/reddit.svg") ||
                  "/asset/image/sns/reddit.svg"
                }
                alt="reddit"
              />
            </Link>
          </SnsWrap>
        </div>
      </Wrap>
      <CopyRight className={headerIndex === 0 ? "home" : ""}>
        Copyright (C) Coinhubkorea. All Rights Reserved.
      </CopyRight>
    </Frame>
  );
}

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* background-color: ${(props) => props.themeColor}; */
  position: absolute;
  z-index: 999;
  bottom: 0;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 966px;
  width: 100%;
  padding-top: 0px;
  margin: 0 auto;
`;

const LogoWrap = styled.div`
  width: 100%;
  max-width: 248px;
  & > img {
    width: 100%;
  }
`;

const MenuWrap = styled.ul`
  display: flex;
  align-items: center;

  &.home li a,
  &.home li button {
    color: #e09d4d;
  }
  &.home > li {
    border-right: 1px solid #00000080;
  }

  & > li {
    font-size: 16px;
    font-weight: 700;
    padding: 4px 10px;
    border-right: 1px solid #fff;
    text-transform: uppercase;
    &:last-child {
      border-right: none;
    }
  }

  & button {
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
  }

  & li.active a {
    color: ${(props) => props.color};
  }
`;

const SnsWrap = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;

  & img {
    width: 30px;
    margin: 20px 10px 0px 10px;
  }
`;

const CopyRight = styled.div`
  font-size: 16px;
  color: #fff;
  opacity: 0.5;
  padding-bottom: 60px;
  padding-top: 40px;
  margin: 0 auto;

  &.home {
    color: rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
`;
