import Layout from "../components/Layout";
import { colorPrimarySelector, headerIndexState } from "../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "@emotion/styled";
import { useEffect } from "react";
import Lottie from "lottie-react";
import { isMobileState } from "../recoil/atoms";
import Desktop from "../containers/home/Desktop";
import Mobile from "../containers/home/Mobile";

export default function Home() {
  const setHeaderIndex = useSetRecoilState(headerIndexState);
  useEffect(() => {
    setHeaderIndex(0);
  }, []);
  const isMobile = useRecoilValue(isMobileState);

  return (
    <Layout>
      {!isMobile && <Desktop />}
      {isMobile && <Mobile />}
    </Layout>
  );
}
