import { useEffect } from "react";
import Layout from "../components/Layout";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { headerIndexState, colorPrimarySelector } from "../recoil/atoms";
import { isMobileState } from "../recoil/atoms";
import Desktop from "../containers/about/Desktop";
import Mobile from "../containers/about/Mobile";

export default function About() {
  const setHeaderIndex = useSetRecoilState(headerIndexState);
  useEffect(() => {
    setHeaderIndex(1);
  }, []);
  const isMobile = useRecoilValue(isMobileState);

  return (
    <Layout>
      {!isMobile && <Desktop />}
      {isMobile && <Mobile />}
    </Layout>
  );
}
