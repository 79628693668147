import styled from "@emotion/styled";
import { useRecoilValue, useRecoilState } from "recoil";
import { colorThirdSelector, isMenuOpenState } from "../../recoil/atoms";
import { Link } from "react-router-dom";

export default function DesktopHeader() {
  const colorThird = useRecoilValue(colorThirdSelector);
  const [isMenuOpen, SetIsMenuOpen] = useRecoilState(isMenuOpenState);
  return (
    <Frame>
      <Wrap>
        <LogoWrap>
          <Link to="/">
            <img
              src={
                isMenuOpen
                  ? "/asset/image/logo-home.svg"
                  : "/asset/image/logo.svg"
              }
              alt="logo"
            />
          </Link>
        </LogoWrap>
        <MenuButton
          isMenuOpen={isMenuOpen}
          onClick={() => {
            SetIsMenuOpen(!isMenuOpen);
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </MenuButton>
      </Wrap>
      <MenuWrap isMenuOpen={isMenuOpen} color={colorThird}>
        <div style={{ width: "100%" }}>
          <Wrap>
            <LogoWrap>
              <img
                src={
                  isMenuOpen
                    ? "/asset/image/logo-home.svg"
                    : "/asset/image/logo.svg"
                }
                alt="logo"
              />
            </LogoWrap>
            <MenuButton
              isMenuOpen={isMenuOpen}
              onClick={() => {
                SetIsMenuOpen(!isMenuOpen);
              }}
            >
              <span></span>
              <span></span>
              <span></span>
            </MenuButton>
          </Wrap>
          <ul>
            <li
              onClick={() => {
                SetIsMenuOpen(!isMenuOpen);
              }}
            >
              <Link to="/about">About</Link>
            </li>
            <li
              onClick={() => {
                SetIsMenuOpen(!isMenuOpen);
              }}
            >
              <Link to="/howtoplay">How to play</Link>
            </li>
            <li
              onClick={() => {
                SetIsMenuOpen(!isMenuOpen);
              }}
            >
              <Link to="https://linktr.ee/forthedog" target="_blank">
                Linktree
              </Link>
            </li>
            <li
              onClick={() => {
                SetIsMenuOpen(!isMenuOpen);
              }}
            >
              <Link to="/whitepaper">Whitepaper</Link>
            </li>
            <li
              onClick={() => {
                SetIsMenuOpen(!isMenuOpen);
              }}
            >
              <Link to="/faq">FAQ</Link>
            </li>
            <li
              onClick={() => {
                window.open("https://market.forthedog.life");
              }}
            >
              MARKETPLACE
            </li>
          </ul>
        </div>
        <SnsWrap>
          <Link to="https://twitter.com/FortheDog_">
            <img src={"/asset/image/sns/home/twitter.svg"} alt="twitter" />
          </Link>
          <Link to="https://t.me/FORTHEDOG">
            <img src={"/asset/image/sns/home/telegram.svg"} alt="telegram" />
          </Link>

          <Link to="https://www.instagram.com/forthedog_life">
            <img src={"/asset/image/sns/home/instagram.svg"} alt="instagram" />
          </Link>

          <Link to="https://discord.gg/UgDk3JAVn2">
            <img src={"/asset/image/sns/home/discord.svg"} alt="discord" />
          </Link>

          <Link to="https://medium.com/@social_29195">
            <img src={"/asset/image/sns/home/midium.svg"} alt="midium" />
          </Link>

          <Link to="https://www.youtube.com/channel/UCB5QGd7wJVVRbMeghD0rqBg ">
            <img src={"/asset/image/sns/home/youtube.svg"} alt="youtube" />
          </Link>

          <Link to="https://www.reddit.com/user/forthedog_official">
            <img src={"/asset/image/sns/home/reddit.svg"} alt="reddit" />
          </Link>
        </SnsWrap>
      </MenuWrap>
    </Frame>
  );
}

const Frame = styled.div`
  position: absolute;
  display: flex;
  z-index: 1001;
  justify-content: space-between;
  width: 100%;
  background-color: "transparent";
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 3vw 5vw 0 5vw;
  margin: 0 auto;
`;

const LogoWrap = styled.div`
  width: 100%;
  max-width: 28vw;
  position: relative;
  & > img {
    width: 100%;
  }
`;

const MenuButton = styled.button`
  width: 4.5vw;
  height: 4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  transform: ${(props) =>
    props.isMenuOpen ? "scale(1.4) translateY(1.5vw)" : ""};

  span {
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: ${(props) => (props.isMenuOpen ? "#e09d4d" : "#fff")};
    display: block;

    &:nth-child(1) {
      transform: ${({ isMenuOpen }) =>
        isMenuOpen ? "rotate(45deg)" : "rotate(0)"};
      position: ${({ isMenuOpen }) => (isMenuOpen ? "absolute" : "unset")};
    }

    &:nth-child(2) {
      opacity: ${({ isMenuOpen }) => (isMenuOpen ? 0 : 1)};
      position: ${({ isMenuOpen }) => (isMenuOpen ? "absolute" : "unset")};
    }

    &:nth-child(3) {
      transform: ${({ isMenuOpen }) =>
        isMenuOpen ? "rotate(-45deg)" : "rotate(0)"};
      position: ${({ isMenuOpen }) => (isMenuOpen ? "absolute" : "unset")};
    }
  }
`;

const MenuWrap = styled.div`
  display: ${(props) => (props.isMenuOpen ? "flex" : "none")};
  visibility: ${(props) => (props.isMenuOpen ? "visible" : "hidden")};
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  background-color: #fff;
  color: #e09d4d;
  position: fixed;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 1000;

  & ul {
    padding-top: 8vw;
    padding-left: 5vw;
  }

  & li {
    padding: 1vw;
    cursor: pointer;
  }

  & a,
  & li {
    font-size: 6vw;
    font-weight: 700;
    color: #e09d4d;
    text-transform: uppercase;
  }
`;

const SnsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8vw 10vw 8vw;
  width: 100%;
  & img {
    width: 7vw;
  }
`;
