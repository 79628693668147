import { useEffect } from "react";
import Layout from "../components/Layout";
import {
  colorPrimarySelector,
  headerIndexState,
  isMobileState,
} from "../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Desktop from "../containers/faq/Desktop";
import Mobile from "../containers/faq/Mobile";

export default function Faq() {
  const setHeaderIndex = useSetRecoilState(headerIndexState);
  useEffect(() => {
    setHeaderIndex(4);
  }, []);
  const isMobile = useRecoilValue(isMobileState);
  return (
    <Layout>
      {!isMobile && <Desktop />}
      {isMobile && <Mobile />}
    </Layout>
  );
}
