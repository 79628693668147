import styled from "@emotion/styled";
import { useRecoilState, useRecoilValue } from "recoil";
import { headerIndexState, isMenuOpenState } from "../../recoil/atoms";
import { Link } from "react-router-dom";

export default function DesktopFooter() {
  const [isMenuOpen, SetIsMenuOpen] = useRecoilState(isMenuOpenState);
  const headerIndex = useRecoilValue(headerIndexState);
  return (
    <Frame>
      <Wrap>
        <LogoWrap>
          <Link to="/">
            <img
              src={
                headerIndex === 0
                  ? "/asset/image/logo-home.svg"
                  : "/asset/image/logo.svg"
              }
              alt="logo"
            />
          </Link>
        </LogoWrap>
        <MenuButton
          className={headerIndex === 0 ? "home" : ""}
          isHome={headerIndex === 0}
          onClick={() => {
            SetIsMenuOpen(!isMenuOpen);
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </MenuButton>
      </Wrap>
      <Copyright className={headerIndex === 0 ? "home" : ""}>
        Copyright (C) Coinhubkorea. All Rights Reserved.
      </Copyright>
    </Frame>
  );
}

const Frame = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 999;
  background-color: "transparent";
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 5vw 1vw 5vw;
  margin: 0 auto;
`;

const LogoWrap = styled.div`
  width: 100%;
  max-width: 28vw;
  position: relative;
  & > img {
    width: 100%;
  }
`;

const MenuButton = styled.button`
  width: 4.5vw;
  height: 4vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  transform: ${(props) =>
    props.isMenuOpen ? "scale(1.4) translateY(1.5vw)" : ""};

  span {
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: ${(props) => (props.isHome ? "#e09d4d" : "#fff")};
    display: block;

    &:nth-child(1) {
      transform: ${({ isMenuOpen }) =>
        isMenuOpen ? "rotate(45deg)" : "rotate(0)"};
      position: ${({ isMenuOpen }) => (isMenuOpen ? "absolute" : "unset")};
    }

    &:nth-child(2) {
      opacity: ${({ isMenuOpen }) => (isMenuOpen ? 0 : 1)};
      position: ${({ isMenuOpen }) => (isMenuOpen ? "absolute" : "unset")};
    }

    &:nth-child(3) {
      transform: ${({ isMenuOpen }) =>
        isMenuOpen ? "rotate(-45deg)" : "rotate(0)"};
      position: ${({ isMenuOpen }) => (isMenuOpen ? "absolute" : "unset")};
    }
  }
`;

const Copyright = styled.div`
  padding-bottom: 8vw;
  padding-top: 3vw;
  font-size: 2.7vw;
  color: #fff;
  opacity: 0.5;
  display: flex;
  justify-content: center;

  &.home {
    color: rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
`;
