import styled from "@emotion/styled";
import Lottie from "lottie-react";
import * as headCloud from "../../lottie/home-head-cloud.json";
import * as footCloud from "../../lottie/home-foot-cloud.json";
import * as roadMap from "../../lottie/home-road-map.json";
import * as teams from "../../lottie/home-teams.json";
import * as test1 from "../../lottie/test-1.json";
import * as test2 from "../../lottie/test-2.json";
import * as test3 from "../../lottie/test-3.json";
import * as count from "../../lottie/home-count.json";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export default function Home() {
  const [ref1, inView1] = useInView({
    triggerOnce: false,
  });
  const [ref2, inView2] = useInView({
    triggerOnce: false,
  });
  const [ref3, inView3] = useInView({
    triggerOnce: false,
  });

  const [play1, setPlay1] = useState(false);
  const [play2, setPlay2] = useState(false);
  const [play3, setPlay3] = useState(false);

  useEffect(() => {
    setPlay1(inView1);
    setPlay2(inView2);
    setPlay3(inView3);
  }, [inView1, inView2, inView3]);

  return (
    <>
      <Frame>
        <BackgroundEmbed
          src="/asset/image/home/home.svg"
          type="image/svg+xml"
          aria-label=""
        ></BackgroundEmbed>
        <FrontEmbed
          src="/asset/image/home/home-foreground.svg"
          type="image/svg+xml"
          aria-label=""
        ></FrontEmbed>

        <IntroAnimation>
          <Lottie animationData={headCloud} />
          <img src="/asset/image/about/intro.png" alt="intro" />
        </IntroAnimation>
        <CountBanner ref={ref1} inview={play1}>
          {play1 && <Lottie animationData={count} loop={false} />}
        </CountBanner>
        <TeamsAnimation ref={ref2}>
          {play2 && <Lottie animationData={teams} loop={false} />}
        </TeamsAnimation>
        <TrioAnimation>
          <Lottie animationData={test1} loop={true} />
          <Lottie animationData={test2} loop={true} />
          <Lottie animationData={test3} loop={true} />
        </TrioAnimation>
        <RoadmapAnimation ref={ref3} inview={play3}>
          {play3 && <Lottie animationData={roadMap} loop={false} />}
        </RoadmapAnimation>
        <EndAnimation>
          <Lottie animationData={footCloud} />
        </EndAnimation>
        <EndManAndDog>
          <img
            src="/asset/image/home/home-bottom-item.png"
            alt="home-bottom-item"
          />
        </EndManAndDog>
        <PartnersAnimation>
          <img src="/asset/image/home/partners.png?v=20230822" alt="partners" />
          <img src="/asset/image/home/partners.png?v=20230822" alt="partners" />
          <img src="/asset/image/home/partners.png?v=20230822" alt="partners" />
        </PartnersAnimation>
      </Frame>
    </>
  );
}

const Frame = styled.div`
  width: 2600px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  svg #home_1_img {
    width: 10px !important;
  }
`;

const BackgroundEmbed = styled.embed`
  position: relative;
  width: 100%;
`;

const FrontEmbed = styled.embed`
  position: absolute;
  z-index: 1;
  width: 954px;
  left: 50%;
  transform: translateX(-50%);
`;

const IntroAnimation = styled.div`
  width: 700px;
  position: absolute;
  top: 60px;
  right: 750px;
  & img {
    position: absolute;
    width: 528px;
    top: 255px;
    right: -30px;
  }
`;

const TrioAnimation = styled.div`
  position: absolute;
  display: flex;
  gap: 110px;
  top: 1230px;
  left: 880px;
  & div {
    width: 200px;
  }
`;

const CountBanner = styled.div`
  width: 516px;
  position: absolute;
  top: 1940px;
  left: 50%;
  transform: translateX(-50%);
  opacity: ${(props) => (props.inview ? 1 : 0)};
  transition: all 0.25s ease-in-out;
`;

const TeamsAnimation = styled.div`
  width: 862px;
  position: absolute;
  top: 2730px;
  left: 50%;
  transform: translateX(-50%);
`;

const RoadmapAnimation = styled.div`
  width: 699px;
  position: absolute;
  top: 3720px;
  left: 50%;
  transform: translateX(-50%);
  opacity: ${(props) => (props.inview ? 1 : 0)};
  transition: all 0.25s ease-in-out;
`;

const PartnersAnimation = styled.div`
  position: absolute;
  width: 100%; // increase the width
  top: 4520px;
  display: flex; // set display property to flex to create a horizontal scroll effect
  animation: scrollLoop 15s linear infinite; // Use the keyframes for the animation
  img {
    width: 50%; // set the width of each image to 50% of the parent div
  }
`;

const EndAnimation = styled.div`
  width: 1400px;
  position: absolute;
  top: 3920px;
  left: 50%;
  transform: translateX(-50%);
`;

const EndManAndDog = styled.div`
  width: 462px;
  position: absolute;
  top: 4860px;
  left: 910px;
`;
