import * as building from "../../lottie/howtoplay-building.json";
import Lottie from "lottie-react";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export default function Mobile({ handleOpen }) {
  const [ref1, inView1] = useInView({
    triggerOnce: false,
  });

  const [play1, setPlay1] = useState(false);

  useEffect(() => {
    setPlay1(inView1);
  }, [inView1]);

  return (
    <>
      <BuildingAnimation ref={ref1} inview={play1}>
        {play1 && <Lottie animationData={building} loop={true} />}
      </BuildingAnimation>
      <Frame>
        <embed
          src="/asset/image/howtoplay/m-howtoplay.svg"
          type="image/svg+xml"
          aria-label=""
        ></embed>
        <VideoButton onClick={handleOpen} />
      </Frame>
    </>
  );
}

const Frame = styled.div`
  max-width: 100%;
  width: 100%;
  position: relative;
`;

const VideoButton = styled.button`
  position: absolute;
  width: 26vw;
  height: 6vw;
  top: 48.7vw;
  left: 4vw;
  background-color: transparent;
`;

const BuildingAnimation = styled.div`
  position: absolute;
  width: 260vw;
  top: 53vw;
  overflow-x: hidden;
  left: 50%;
  transform: translate(-50%, -30vw);
  transition: all 0.25s ease-in;
  opacity: ${(props) => (props.inview ? 1 : 0)};
`;
