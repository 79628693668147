import styled from "@emotion/styled";
import Lottie from "lottie-react";
import * as headCloud from "../../lottie/home-head-cloud.json";
import * as footCloud from "../../lottie/home-foot-cloud.json";
import * as roadMap from "../../lottie/home-road-map.json";
import * as teams from "../../lottie/home-teams-m.json";
import * as test1 from "../../lottie/test-1.json";
import * as test2 from "../../lottie/test-2.json";
import * as test3 from "../../lottie/test-3.json";
import * as count from "../../lottie/home-count.json";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";

export default function Home() {
  const [ref1, inView1] = useInView({
    triggerOnce: false,
  });
  const [ref2, inView2] = useInView({
    triggerOnce: false,
  });
  const [ref3, inView3] = useInView({
    triggerOnce: false,
  });
  const [ref4, inView4] = useInView({
    triggerOnce: false,
  });
  const [ref5, inView5] = useInView({
    triggerOnce: false,
  });
  const [ref6, inView6] = useInView({
    triggerOnce: false,
  });

  const [play1, setPlay1] = useState(false);
  const [play2, setPlay2] = useState(false);
  const [play3, setPlay3] = useState(false);
  const [play4, setPlay4] = useState(false);
  const [play5, setPlay5] = useState(false);
  const [play6, setPlay6] = useState(false);

  useEffect(() => {
    setPlay1(inView1);
    setPlay2(inView2);
    setPlay3(inView3);
    setPlay4(inView4);
    setPlay5(inView5);
    setPlay6(inView6);
  }, [inView1, inView2, inView3, inView4, inView5, inView6]);

  return (
    <>
      <Frame>
        <embed
          src="/asset/image/home/m-home.svg"
          type="image/svg+xml"
          aria-label=""
        ></embed>
        <IntroAnimation>
          <Lottie animationData={headCloud} />
          <img src="/asset/image/about/intro.png" alt="intro" />
        </IntroAnimation>
        <CountBanner ref={ref1} inview={play1}>
          {play1 && <Lottie animationData={count} loop={false} />}
        </CountBanner>
        <TeamsAnimation ref={ref2}>
          {play2 && <Lottie animationData={teams} loop={false} />}
        </TeamsAnimation>
        <TrioAnimation>
          <Lottie className="trio1" animationData={test1} loop={true} />

          <Lottie className="trio2" animationData={test2} loop={true} />

          <Lottie className="trio3" animationData={test3} loop={true} />
        </TrioAnimation>
        <RoadmapAnimation ref={ref3} inview={play3}>
          {play3 && <Lottie animationData={roadMap} loop={false} />}
        </RoadmapAnimation>
        <EndAnimation>
          <Lottie animationData={footCloud} />
        </EndAnimation>
        <Partners>
          <img
            src="/asset/image/home/partners-m.png?v=20230822"
            alt="partners"
          />
        </Partners>
        <AppStoreButton
          onClick={() => {
            alert("Comming soon");
          }}
        />
      </Frame>
    </>
  );
}

const Frame = styled.div`
  max-width: 100%;
  width: 100%;
  position: relative;

  svg #home_1_img {
    width: 10px !important;
  }
`;

const IntroAnimation = styled.div`
  width: 60vw;
  position: absolute;
  top: 48vw;
  right: 0vw;
  & img {
    position: absolute;
    width: 60vw;
    top: 14vw;
    left: 5.5vw;
  }
`;

const TeamsAnimation = styled.div`
  width: 90vw;
  position: absolute;
  top: 634.5vw;
  right: 5vw;
`;

const RoadmapAnimation = styled.div`
  width: 80vw;
  position: absolute;
  top: 785vw;
  right: 10vw;
  opacity: ${(props) => (props.inview ? 1 : 0)};
  transition: all 0.25s ease-in-out;
`;

const EndAnimation = styled.div`
  position: absolute;
  left: -120vw;
  bottom: 60vw;
  overflow: hidden;
  width: 300vw;
  & svg {
  }
`;
const CountBanner = styled.div`
  width: 80vw;
  position: absolute;
  left: 10vw;
  top: 497vw;
  opacity: ${(props) => (props.inview ? 1 : 0)};
  transition: all 0.25s ease-in-out;
`;

const TrioAnimation = styled.div`
  & .trio1 {
    position: absolute;
    width: 48vw;
    top: 204vw;
    right: 8vw;
  }
  & .trio2 {
    position: absolute;
    width: 48vw;
    top: 294vw;
    left: 6vw;
  }
  & .trio3 {
    position: absolute;
    width: 48vw;
    top: 385vw;
    right: 6vw;
  }
`;

const Partners = styled.div`
  position: absolute;
  bottom: 184vw;
  width: 80vw;
  left: 10vw;
`;

const AppStoreButton = styled.div`
  width: 25vw;
  height: 5.5vw;
  position: absolute;
  top: 63vw;
  left: 33.5vw;
  z-index: 9900;
  background-color: transparent;
  cursor: pointer;
`;
