import { headerIndexState, isMobileState } from "../../recoil/atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";

export default function Header() {
  const isMobile = useRecoilValue(isMobileState);
  return (
    <>
      {/* <DesktopHeader /> */}
      {!isMobile && <DesktopHeader />}
      {isMobile && <MobileHeader />}
    </>
  );
}
