import { useRecoilValue } from "recoil";
import { colorPrimarySelector, isMobileState } from "../../recoil/atoms";
import styled from "@emotion/styled";
import { useState } from "react";
import * as hero from "../../lottie/faq-hero.json";
import Lottie from "lottie-react";

export default function Desktop() {
  const colorPrimary = useRecoilValue(colorPrimarySelector);
  const [isClose1, setIsClose1] = useState(false);
  const [isClose2, setIsClose2] = useState(false);
  const [isClose3, setIsClose3] = useState(false);
  const [isClose4, setIsClose4] = useState(false);
  const [isClose5, setIsClose5] = useState(false);
  const [isClose6, setIsClose6] = useState(false);
  const [isClose7, setIsClose7] = useState(false);
  return (
    <Frame themeColor={colorPrimary}>
      <BackgroundEmbed
        src="/asset/image/faq/faq.svg"
        type="image/svg+xml"
        aria-label=""
      ></BackgroundEmbed>

      <HeroAnimation>
        <Lottie animationData={hero} />
      </HeroAnimation>

      <QuestionBoxWrap>
        <QuestionBox className={isClose1 ? "close" : ""}>
          <div>
            <h2>What is the FTD(For The Dog) project?</h2>
            <button
              onClick={() => {
                setIsClose1(!isClose1);
              }}
            />
          </div>
          <Devider />
          <p>
            FTD is a blockchain-based M2E (Move to Earn) service that allows you
            to enjoy exercise, health, and rewards through walking with your dog
            NFT. BTD tokens are rewarded every time you go for a walk with your
            dog NFT.
          </p>
        </QuestionBox>
        <QuestionBox className={isClose2 ? "close" : ""}>
          <div>
            <h2>How do I download FTD App?</h2>
            <button
              onClick={() => {
                setIsClose2(!isClose2);
              }}
            />
          </div>
          <Devider />
          <p>
            FTD App can only be downloaded from the Google Play Store, or the
            Apple App Store.
          </p>
        </QuestionBox>
        <QuestionBox className={isClose3 ? "close" : ""}>
          <div>
            <h2>How do I play FTD App?</h2>
            <button
              onClick={() => {
                setIsClose3(!isClose3);
              }}
            />
          </div>
          <Devider />
          <p>
            Please refer to our How to Play link for more detailed guidelines.
          </p>
          <img
            src="/asset/image/faq/faq-button1.png"
            alt="faq1"
            onClick={() => {
              window.open("/howtoplay");
            }}
          />
        </QuestionBox>
        <QuestionBox className={isClose4 ? "close" : ""}>
          <div>
            <h2>What are $BTD Tokens?</h2>
            <button
              onClick={() => {
                setIsClose4(!isClose4);
              }}
            />
          </div>
          <Devider />
          <p>
            Game token for FTD : BTD
            <br />
            BTD is a virtual coin that can be earned through various activities
            such as
            <br />
            walking and jogging and more.
            <br />
            It is exclusively used for making payments within the three FTD
            platfoms
          </p>
        </QuestionBox>
        <QuestionBox className={isClose5 ? "close" : ""}>
          <div>
            <h2>Where can I purchase FTD NFTs?</h2>
            <button
              onClick={() => {
                setIsClose5(!isClose5);
              }}
            />
          </div>
          <Devider />
          <p>Available on the FTD Marketplace.</p>
          <img
            src="/asset/image/faq/faq-button2.png"
            alt="faq2"
            onClick={() => {
              window.open("https://market.forthedog.life");
            }}
          />
        </QuestionBox>
        <QuestionBox className={isClose6 ? "close" : ""}>
          <div>
            <h2>
              What is the Of the Dog{" "}
              <span>(hereinafter referred to as OTD)</span> platform?
            </h2>
            <button
              onClick={() => {
                setIsClose6(!isClose6);
              }}
            />
          </div>
          <Devider />
          <p style={{ paddingLeft: "20px", textIndent: "-20px" }}>
            The OTD platform is an e-commerce platform based on web3 technology.
            It offers the following services: On the By The Dog platform, you
            can purchase NFTs of various mockup products held by the FTD
            foundation or even monetize them.
            <br />
            Additionally, you can sell a wide range of products in samll
            quantities through on your personal online or offline shops You can
            sell custom products online with no minimums through the OTD
            platform.
          </p>
        </QuestionBox>
        <QuestionBox className={isClose7 ? "close" : ""}>
          <div>
            <h2>
              What is the By the Dog{" "}
              <span>(hereinafter referred to as BTD)</span> marketplace
              platform?
            </h2>
            <button
              onClick={() => {
                setIsClose7(!isClose7);
              }}
            />
          </div>
          <Devider />
          <p style={{ paddingLeft: "20px", textIndent: "-20px" }}>
            The BTD marketplace provides the following services:
            <br />
            You can purchase professional PET NFTs created by the foundation.
            Through the FTD app, you can level up your NFTs and resell them at a
            higher value. Mint your own NFTs created by AI designers for higher
            alue and untilize them on the app. You can use the reward points you
            earn from in-app purchases in the marketplace. The BTD marketplace
            features a wallet function that allows you to swap coins. It serves
            as an open marketplace that leverages the OTD e-commers platform.
          </p>
        </QuestionBox>
      </QuestionBoxWrap>
      <ColorBar />
    </Frame>
  );
}

const Frame = styled.div`
  width: 2600px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

const BackgroundEmbed = styled.embed`
  position: relative;
  width: 100%;
`;

const FrontEmbed = styled.embed`
  position: absolute;
  z-index: 1;
  width: 967.47px;
  left: 50%;
  transform: translateX(-50%);
`;

const HeroAnimation = styled.div`
  position: absolute;
  width: 356px;
  top: 335px;
  left: 1230px;
  z-index: 100;
`;

const QuestionBoxWrap = styled.div`
  position: absolute;
  top: 600px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 200;
`;

const QuestionBox = styled.div`
  background-color: #ff6e5c;
  border-radius: 50px;
  max-width: 893px;
  min-height: 200px;
  margin: auto;
  padding: 20px 30px 25px 30px;
  margin-bottom: 20px;

  & div {
    display: flex;
    justify-content: space-between;
  }
  & button {
    width: 40px;
    height: 40px;
    background-image: url("/asset/image/about/icon-check2.svg");
    background-size: cover;
  }
  & h2 {
    width: 90%;
    color: #fff;
    font-size: 25px;
    font-weight: 700;
  }
  & span {
    font-size: 15px !important;
  }
  & p {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
  }

  &.close {
    min-height: 0px;
  }
  &.close p,
  &.close hr {
    display: none;
  }
  &.close button {
    transform: rotateX(180deg);
  }

  img {
    width: 225px;
    margin-left: auto;
    cursor: pointer;
  }
`;

const Devider = styled.hr`
  background: white;
  height: 1px;
  border: 0;
  margin: 15px 0;
`;

const ColorBar = styled.div`
  position: absolute;
  width: 100%;
  height: 20px;
  background-color: #7a2b2b;
  bottom: 0;
`;
