import styled from "@emotion/styled";
import First from "./First";
import * as cloud from "../../lottie/about-cloud.json";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export default function Desktop() {
  const [ref1, inView1] = useInView({
    triggerOnce: false,
  });

  const [play1, setPlay1] = useState(false);

  useEffect(() => {
    setPlay1(inView1);
  }, [inView1]);

  return (
    <>
      <Frame>
        <BackgroundEmbed
          src="/asset/image/about/about-background.svg"
          type="image/svg+xml"
          aria-label=""
        ></BackgroundEmbed>
        <FrontEmbed
          src="/asset/image/about/about-foreground.svg"
          type="image/svg+xml"
          aria-label=""
        ></FrontEmbed>
        <First />

        <CloudAnimation inview={play1}>
          {play1 && <Lottie animationData={cloud} loop={false} speed={0.3} />}
        </CloudAnimation>
        <CloudAnimationPlayPosition ref={ref1} />
      </Frame>
    </>
  );
}

const Frame = styled.div`
  width: 2600px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

const BackgroundEmbed = styled.embed`
  position: relative;
  width: 100%;
`;

const FrontEmbed = styled.embed`
  position: absolute;
  z-index: 3;
  width: 920px;
  left: 50%;
  top: 193px;
  transform: translateX(-50%);
`;

const CloudAnimation = styled.div`
  position: absolute;
  width: 2000px;
  top: 916px;
  left: 304px;
  z-index: 2;
  transition: all 0.25s ease-in;
  opacity: ${(props) => (props.inview ? 1 : 0)};
`;

const CloudAnimationPlayPosition = styled.div`
  position: absolute;
  top: 1400px;
  height: 500px;
  width: 100%;
`;
