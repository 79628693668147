import React from "react";
import About from "./pages/About";
import Faq from "./pages/Faq";
import Home from "./pages/Home";
import HowToPlay from "./pages/HowToPlay";
import Whitepaper from "./pages/Whitepaper";
import NotFound from "./pages/NotFound";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import useResize from "./hooks/useResize";

function App() {
  useResize();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/howtoplay" element={<HowToPlay />} />
        <Route path="/whitepaper" element={<Whitepaper />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
