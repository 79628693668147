import Header from "./Header";
import Footer from "./Footer";
import { colorPrimarySelector } from "../recoil/atoms";
import { useRecoilValue, useRecoilState } from "recoil";
import styled from "@emotion/styled";

export default function Layout({ children }) {
  const colorPrimary = useRecoilValue(colorPrimarySelector);
  return (
    <div style={{ position: "relative", display: "block" }}>
      <Header />
      <Frame themeColor={colorPrimary}>{children}</Frame>
      <Footer />
    </div>
  );
}

const Frame = styled.div`
  background-color: ${(props) => props.themeColor};
  display: block;
  width: 100%;
  /* padding-top: 135px; */
`;
