// recoil atoms

import { atom, selector } from "recoil";

export const isMobileState = atom({
  key: "isMobileState",
  default: false,
});

export const headerIndexState = atom({
  key: "headerIndexState",
  default: 0, // 0: home, 1: about, 2. howtoplay, 3: whitepaper, 3: linktree, 4: faq
});

export const colorPrimarySelector = selector({
  key: "colorPrimarySelector",
  get: ({ get }) => {
    const headerIndex = get(headerIndexState);
    switch (headerIndex) {
      case 0:
        return "#fff";
      case 1:
        return "#3E5C83";
      case 2:
        return "#363666";
      case 3:
        return "#328062";
      case 4:
        return "#D44C41";
      default:
        return "#E09D4D";
    }
  },
});

export const colorSecondarySelector = selector({
  key: "colorSecondarySelector",
  get: ({ get }) => {
    const headerIndex = get(headerIndexState);
    switch (headerIndex) {
      case 0:
        return "#F7B451";
      case 1:
        return "#4D99C3";
      case 2:
        return "#363666";
      case 3:
        return "#275643";
      case 4:
        return "#A83B35";
      default:
        return "#F7B451";
    }
  },
});

export const colorThirdSelector = selector({
  key: "colorThirdSelector",
  get: ({ get }) => {
    const headerIndex = get(headerIndexState);
    switch (headerIndex) {
      case 0:
        return "#7F6117";
      case 1:
        return "#253864";
      case 2:
        return "#6C6CA3";
      case 3:
        return "#224938";
      case 4:
        return "#7A2B2B";
      default:
        return "#7F6117";
    }
  },
});

export const colorFooterSelector = selector({
  key: "colorFooterSelector",
  get: ({ get }) => {
    const headerIndex = get(headerIndexState);
    switch (headerIndex) {
      case 0:
        return "#7F6117";
      case 1:
        return "#3f5c83";
      case 2:
        return "#6c6ca3";
      case 3:
        return "#328062";
      case 4:
        return "#D44C41";
      default:
        return "#fff";
    }
  },
});

export const isMenuOpenState = atom({
  key: "isMenuOpenState",
  default: false,
});
