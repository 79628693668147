import { useEffect } from "react";

import Layout from "../components/Layout";
import { colorPrimarySelector, headerIndexState } from "../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isMobileState } from "../recoil/atoms";
import Desktop from "../containers/howtoplay/Desktop";
import Mobile from "../containers/howtoplay/Mobile";
import ReactPlayer from "react-player";
import { Button, Modal } from "@mui/material";
import { useRef, useState } from "react";

export default function HowToPlay() {
  const setHeaderIndex = useSetRecoilState(headerIndexState);
  useEffect(() => {
    setHeaderIndex(2);
  }, []);
  const isMobile = useRecoilValue(isMobileState);

  const videoRef = useRef(null);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Layout>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            maxWidth: "100%",
            width: "100%",
            transform: "translate(-50%, -50%)",
            margin: "0 auto",
          }}
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <ReactPlayer
              playing
              muted
              loop
              playsinline
              url="/asset/video/howtoplay.mp4"
              ref={videoRef}
              style={{
                position: "relative",
                maxWidth: isMobile ? "100%" : "640px",
                margin: "0 auto",
              }}
            />
            <button
              onClick={handleClose}
              style={{
                fontSize: "1.5rem",
                fontWeight: 700,
                position: "absolute",
                bottom: (!isMobile && "-30px") || "30px",
                left: "50%",
                transform: "translate(-50%, 0)",
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>

      {!isMobile && <Desktop handleOpen={handleOpen} />}
      {isMobile && <Mobile handleOpen={handleOpen} />}
    </Layout>
  );
}
