import DesktopFooter from "./DesktopFooter";
import MobileFooter from "./MobileFooter";
import { isMobileState } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";

export default function Footer() {
  const isMobile = useRecoilValue(isMobileState);

  return (
    <>
      {!isMobile && <DesktopFooter />}
      {isMobile && <MobileFooter />}
    </>
  );
}
