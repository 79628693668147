import { useRef } from "react";
import { useRecoilValue } from "recoil";
import { colorPrimarySelector } from "../../recoil/atoms";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import * as aboutIntro from "../../lottie/about-intro.json";
import Lottie from "lottie-react";
import { useInView } from "react-intersection-observer";

export default function First() {
  const colorPrimary = useRecoilValue(colorPrimarySelector);
  const [isFirstClicked, setIsFirstClicked] = useState(false);
  const [isSecondClicked, setIsSecondClicked] = useState(false);
  const [isThirdClicked, setIsThirdClicked] = useState(false);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const [inviewRef1, inView1] = useInView({
    triggerOnce: false,
  });
  const [play1, setPlay1] = useState(false);

  useEffect(() => {
    setPlay1(inView1);
  }, [inView1]);

  return (
    <Frame themeColor={colorPrimary}>
      <Wrap ref={inviewRef1} inview={play1}>
        <IntroAnimation>
          {play1 && <Lottie animationData={aboutIntro} loop={false} />}
        </IntroAnimation>
        <button
          ref={ref1}
          className={isFirstClicked ? "hidden" : "check1"}
          onClick={() => {
            setIsFirstClicked(true);
          }}
        />
        <button
          ref={ref2}
          className={isSecondClicked ? "hidden" : "check2"}
          onClick={() => {
            setIsSecondClicked(true);
          }}
        />
        <button
          ref={ref3}
          className={isThirdClicked ? "hidden" : "check3"}
          onClick={() => {
            setIsThirdClicked(true);
          }}
        />
        <img
          className={"desc1 ".concat(isFirstClicked ? "active" : "")}
          src="/asset/image/about/m-desc-1.png"
          alt="desc-1"
        />
        <img
          className={"desc2 ".concat(isSecondClicked ? "active" : "")}
          src="/asset/image/about/m-desc-2.png"
          alt="desc-2"
        />
        <img
          className={"desc3 ".concat(isThirdClicked ? "active" : "")}
          src="/asset/image/about/m-desc-3.png"
          alt="desc-3"
        />

        <button
          className={!isFirstClicked ? "hidden" : "close1"}
          onClick={() => {
            setIsFirstClicked(false);
          }}
        />
        <button
          className={!isSecondClicked ? "hidden" : "close2"}
          onClick={() => {
            setIsSecondClicked(false);
          }}
        />
        <button
          className={!isThirdClicked ? "hidden" : "close3"}
          onClick={() => {
            setIsThirdClicked(false);
          }}
        />
      </Wrap>
    </Frame>
  );
}

const Frame = styled.div`
  position: absolute;
  top: 50vw;
  left: 35vw;
  z-index: 4;
`;
const Wrap = styled.div`
  width: 72vw;
  height: 80vw;
  position: relative;
  margin: 0 auto;
  transition: all 0.25s ease-in;
  opacity: ${(props) => (props.inview ? 1 : 0)};

  & button {
    width: 9vw;
    height: 9vw;
    background-image: url("/asset/image/about/icon-check.png");
    background-size: cover;
    position: absolute;
    z-index: 1;
    transition: transform 0.3s ease-in;

    &.check1 {
      top: 48%;
      left: -33%;
      transform: scale(1);
    }
    &.check2 {
      top: 48%;
      right: 28%;
      transform: scale(1);
    }
    &.check3 {
      bottom: -32%;
      left: 12%;
      transform: scale(1);
    }
    &.hidden {
      display: none;
    }
    &.active {
      display: block;
    }

    &.close1 {
      width: 6vw;
      height: 6vw;
      background-image: url("/asset/image/about/icon-close.png");
      left: -20%;
      top: 52%;
      z-index: 200;
    }
    &.close2 {
      width: 6vw;
      height: 6vw;
      background-image: url("/asset/image/about/icon-close.png");
      right: 18%;
      top: 54%;
      z-index: 200;
    }
    &.close3 {
      width: 6vw;
      height: 6vw;
      background-image: url("/asset/image/about/icon-close.png");
      left: 25%;
      bottom: -70%;
      z-index: 200;
    }
  }

  & img {
    width: 30vw;
    height: calc(15vw * 1.36vw);
    position: absolute;
    display: none;
    z-index: 200;

    &.active {
      display: block;
    }
    &.desc1 {
      top: 10%;
      left: -45%;
    }
    &.desc2 {
      top: 12%;
      right: 10%;
    }
    &.desc3 {
      bottom: -72%;
      left: 0%;
    }
  }
`;

const IntroAnimation = styled.div`
  position: absolute;
  width: 120vw;
  left: -45.5vw;
`;
