import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "@emotion/styled";
import * as greenEarth from "../../lottie/whitepaer-green-earth.json";
import * as hero from "../../lottie/whitepaper-hero.json";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export default function Desktop() {
  const [ref1, inView1] = useInView({
    triggerOnce: false,
  });

  const [play1, setPlay1] = useState(false);

  useEffect(() => {
    setPlay1(inView1);
  }, [inView1]);

  return (
    <Frame>
      <HeroAnimation>
        <Lottie animationData={hero} />
      </HeroAnimation>
      <BackgroundEmbed
        src="/asset/image/whitepaper/whitepaper.svg"
        type="image/svg+xml"
        aria-label=""
      ></BackgroundEmbed>
      <LayerWrap>
        <FrontEmbed
          src="/asset/image/whitepaper/whitepaper-2.svg"
          type="image/svg+xml"
          aria-label=""
        ></FrontEmbed>
        <GreenEarthAnimation inview={play1} ref={ref1}>
          {play1 && <Lottie animationData={greenEarth} loop={false} />}
        </GreenEarthAnimation>
      </LayerWrap>
      <ColorBar className="asdasd" />
    </Frame>
  );
}

const Frame = styled.div`
  width: 2600px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

const BackgroundEmbed = styled.embed`
  position: relative;
  width: 100%;
`;

const FrontEmbed = styled.embed`
  position: relative;
  /* z-index: 1; */
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
`;

const ColorBar = styled.div`
  position: absolute;
  width: 100%;
  height: 20px;
  background-color: #224938;
  bottom: 0;
`;

const HeroAnimation = styled.div`
  position: absolute;
  z-index: 1;
  width: 430px;
  top: 420px;
  left: 1150px;
  transform: translateX(-50%);
`;

const LayerWrap = styled.div`
  position: absolute;
  z-index: 100;
  top: 520px;
  width: 100%;
`;

const GreenEarthAnimation = styled.div`
  position: absolute;
  width: 870px;
  top: 275px;
  left: 980px;
  z-index: 3;
  opacity: ${(props) => (props.inview ? 1 : 0)};
  transition: all 0.25s ease-in;
`;
